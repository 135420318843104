const ReferenceItem = () => {
    
    return (
        <div>
            <div className="reference">
                <img className="w-100" src='https://cdn.pixabay.com/photo/2020/12/06/22/51/ship-5810249_1280.jpg' alt="team" />
            </div>
        </div>
    )
}

export default ReferenceItem;