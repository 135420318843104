const ReferenceItem2 = () => {

    return (
        <div>
            <div className="reference">
                <img className="w-100" src='https://cdn.pixabay.com/photo/2016/09/12/19/08/pallet-1665471_1280.jpg' alt="team" />
            </div>
        </div>
    )
}

export default ReferenceItem2;