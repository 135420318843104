const ReferenceItem3 = () => {
   
    return (
        <div>
            <div className="reference">
                <img className="w-100" src='https://cdn.pixabay.com/photo/2015/03/16/07/56/port-675539_1280.jpg' alt="team" />
            </div>
        </div>
    )
}

export default ReferenceItem3;